import * as THREE from 'three'
import Model from './Abstracts/Model.js'
import Experience from '../Experience.js'
import Debug from '../Utils/Debug.js'
import State from "../State.js";
import Materials from "../Materials/Materials.js";
import Pilot from "./Pilot.js";

export default class AirPlane extends Model {
    experience = Experience.getInstance()
    debug = Debug.getInstance()
    state = State.getInstance()
    materials = Materials.getInstance()
    scene = experience.scene
    time = experience.time
    camera = experience.camera.instance
    renderer = experience.renderer.instance
    resources = experience.resources
    container = new THREE.Group();
    colors = this.state.colors
    isMobile = this.experience.isMobile

    constructor() {
        super()

        this.setModel()
        this.setDebug()
    }

    setModel() {
        this.model = this.resources.items.bagModel.scene
        this.model.scale.setScalar( 115 )

        if( this.isMobile ) {
            this.model.scale.setScalar( 135 )
        }


        this.model.rotation.y = Math.PI / 2
        this.model.rotation.y -= .5
        this.model.rotation.x += .3
        //this.model.rotation.z = Math.PI / 6
        //this.model.rotation.x = Math.PI / 4
        //this.model.position.y -= 40;

        // Change center model to boundingBox
        const box = new THREE.Box3().setFromObject( this.model );
        const centerBox = box.getCenter( new THREE.Vector3() );
        this.model.position.sub( centerBox );

        this.model.traverse( child => {
                if ( child.isMesh ) {
                    child.castShadow = true
                    child.receiveShadow = true
                }
            }
        )

        this.mesh = new THREE.Object3D();
        this.mesh.name = "airPlane";

        this.mesh.add( this.model );

        // // Использование BoxHelper для визуализации границ
        // const boxHelper = new THREE.BoxHelper(this.mesh, 0xffff00);
        //
        // this.mesh.add(boxHelper);
    }

    resize() {

    }

    setDebug() {
        if ( !this.debug.active ) return

    }

    update( deltaTime ) {

    }

}
