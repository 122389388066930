import * as THREE from 'three'
import Model from './Abstracts/Model.js'
import Experience from '../Experience.js'
import Debug from '../Utils/Debug.js'
import State from "../State.js";
import Materials from "../Materials/Materials.js";
import * as BufferGeometryUtils from "three/addons/utils/BufferGeometryUtils.js";

export default class Coin extends Model {
    experience = Experience.getInstance()
    debug = Debug.getInstance()
    state = State.getInstance()
    materials = Materials.getInstance()
    scene = experience.scene
    time = experience.time
    camera = experience.camera.instance
    renderer = experience.renderer.instance
    resources = experience.resources
    container = new THREE.Group();
    colors = this.state.colors

    constructor() {
        super()

        this.setModel()
        this.setDebug()
    }

    setModel() {
        this.mesh = this.resources.items.adidasLogoModel.scene.clone();

        this.mesh.scale.set( 40, 40, 40 );

        // // const geom = new THREE.TetrahedronGeometry( 5, 0 );
        // const mat = new THREE.MeshPhongMaterial( {
        //     color: 0x009999,
        //     shininess: 0,
        //     specular: 0xffffff,
        //     flatShading: true
        // } );
        //
        // this.mesh.material = mat

        // this.mesh = new THREE.Mesh( geom, mat );
        this.mesh.castShadow = true;
        this.angle = 0;
        this.dist = 0;
    }

    resize() {

    }

    setDebug() {
        if ( !this.debug.active ) return

    }

    update( deltaTime ) {

    }

}
