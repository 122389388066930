export default [
    // {
    //     name: 'backgroundSound',
    //     type: 'audio',
    //     path: '/sounds/background.mp3'
    // },
    {
        name: 'adidasLogoModel',
        type: 'gltfModel',
        path: '/models/adidas_logo_2.glb'
    },
    {
        name: 'chalkBoardModel',
        type: 'gltfModel',
        path: '/models/chalk_board.glb'
    },
    {
        name: 'eraserModel',
        type: 'gltfModel',
        path: '/models/eraser.glb'
    },
    {
        name: 'pencilModel',
        type: 'gltfModel',
        path: '/models/pencil.glb'
    },
    {
        name: 'rulerModel',
        type: 'gltfModel',
        path: '/models/ruler.glb'
    },
    {
        name: 'bagModel',
        type: 'gltfModel',
        path: '/models/bag.glb'
    },
    {
        name: 'flyingBallModel',
        type: 'gltfModel',
        path: '/models/flying_ball.glb'
    },

    // Textures

    // {
    //     name: 'displacementTexture',
    //     type: 'texture',
    //     path: '/textures/displacement.jpg'
    // },
    {
        name: 'backgroundTexture',
        type: 'texture',
        path: '/textures/background_7.png'
    }
]
