import * as THREE from 'three'
import Experience from '../Experience.js'
import { RoomEnvironment } from 'three/addons/environments/RoomEnvironment.js';

export default class Environment {
    constructor() {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.debug = this.experience.debug
        this.renderer = this.experience.renderer.instance

        this.scene.colorSpace = THREE.SRGBColorSpace

        this.setAmbientLight()
        this.setDirectionalLight()
        this.setEnvironmentMap()

        this.setDebug()
    }

    setAmbientLight() {
        this.ambientLight = new THREE.AmbientLight( '#ffffff', 0.05 )
        this.scene.add( this.ambientLight )
    }

    setDirectionalLight() {
        this.directionalLight = new THREE.DirectionalLight( '#ffffff', 2 )
        this.directionalLight.position.set( 0, -10, 5 )
        this.scene.add( this.directionalLight )
    }


    setEnvironmentMap() {
        // const canvas = document.createElement('canvas');
        // canvas.width = 10; // Установите подходящие размеры
        // canvas.height = 10;
        //
        // const context = canvas.getContext('2d');
        //
        // const gradient = context.createLinearGradient(0, 0, 0, canvas.height);
        // gradient.addColorStop(0, '#e4e0ba');
        // gradient.addColorStop(1, '#f7d9aa');
        //
        // context.fillStyle = gradient;
        // context.fillRect(0, 0, canvas.width, canvas.height);
        //
        // const texture = new THREE.CanvasTexture(canvas);

        const backgroundTexture = this.resources.items.backgroundTexture
        backgroundTexture.colorSpace = THREE.SRGBColorSpace

        this.scene.background = backgroundTexture;
        this.scene.backgroundIntensity = 1.0
        this.scene.backgroundBlurriness = 10.5;
        // this.scene.environment = texture;
        // this.scene.environmentIntensity = 34;

        const environment = new RoomEnvironment( this.renderer );
        const pmremGenerator = new THREE.PMREMGenerator( this.renderer );
        // //
        const env = pmremGenerator.fromScene( environment ).texture;
        // this.scene.background = env;
        this.environment = env
        this.scene.environment = env
        this.scene.environmentIntensity = 0.5
        // this.scene.backgroundBlurriness = 0.5;
        // //environment.dispose();

        // //set background transparent
        // this.scene.background = null;

    }

    setDebug() {
        if ( this.debug.active ) {

        }
    }
}
