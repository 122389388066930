import * as THREE from 'three'
import Model from './Abstracts/Model.js'
import Experience from '../Experience.js'
import Debug from '../Utils/Debug.js'
import State from "../State.js";
import Materials from "../Materials/Materials.js";
import * as BufferGeometryUtils from "three/addons/utils/BufferGeometryUtils.js";

import customLambertMaterialVertex from "@experience/Shaders/LambertMaterial/vertex.glsl";
import customLambertMaterialFragment from "@experience/Shaders/LambertMaterial/fragment.glsl";

export default class Sea extends Model {
    experience = Experience.getInstance()
    debug = Debug.getInstance()
    state = State.getInstance()
    materials = Materials.getInstance()
    sizes = experience.sizes
    scene = experience.scene
    time = experience.time
    camera = experience.camera.instance
    renderer = experience.renderer.instance
    resources = experience.resources
    container = new THREE.Group();
    colors = this.state.colors

    u_FlowFieldFrequency = 0.003
    u_FlowFieldStrength = 36.0
    u_TimeScale = 0.4

    constructor() {
        super()

        this.setModel()
        //this.setDebug()
    }

    setModel() {
        let geom = new THREE.CylinderGeometry( this.state.game.seaRadius, this.state.game.seaRadius, this.state.game.seaLength, 128, 128 );
        geom.applyMatrix4( new THREE.Matrix4().makeRotationX( -Math.PI / 2 ) );
        geom.computeTangents()


        let material = this.material = new THREE.MeshLambertMaterial( {
            color: this.colors.blue,
            transparent: true,
            opacity: .8,
            flatShading: false,
            fog: true,
        } );


        material.onBeforeCompile = (shader) => {
            shader.vertexShader = customLambertMaterialVertex
            shader.fragmentShader = customLambertMaterialFragment

            material.uniforms = shader.uniforms;
            material.uniforms.u_Time = new THREE.Uniform( 0 );
            material.uniforms.u_FlowFieldFrequency = new THREE.Uniform( this.u_FlowFieldFrequency );
            material.uniforms.u_FlowFieldStrength = new THREE.Uniform( this.u_FlowFieldStrength );
            material.uniforms.u_TimeScale = new THREE.Uniform( this.u_TimeScale );
            material.uniforms.u_Resolution = new THREE.Uniform( new THREE.Vector2( this.sizes.width * this.sizes.pixelRatio, this.sizes.height * this.sizes.pixelRatio ) ),
            material.uniforms.u_BackgroundTexture = new THREE.Uniform( this.resources.items.backgroundTexture );

            shader.defines = {}
            shader.defines.USE_TANGENT = true;

            this.setDebug()
        }

        this.mesh = new THREE.Mesh( geom, material );
        this.mesh.name = "waves";
        this.mesh.receiveShadow = true;
    }

    moveWaves() {
        // let positionAttribute = this.mesh.geometry.getAttribute('position');
        // let l = positionAttribute.count;
        //
        // for (let i = 0; i < l; i++) {
        //     let vprops = this.waves[i];
        //
        //     let x = vprops.x + Math.cos(vprops.ang) * vprops.amp;
        //     let y = vprops.y + Math.sin(vprops.ang) * vprops.amp;
        //     let z = vprops.z; // Assuming z remains unchanged, if not modify similarly
        //
        //     positionAttribute.setXYZ(i, x, y, z);
        //
        //     vprops.ang += vprops.speed * this.time.delta;
        // }
        //
        // positionAttribute.needsUpdate = true;
    }

    resize() {

    }

    setDebug() {
        if ( !this.debug.active ) return

        this.debugFolder = this.debug.panel.addFolder("Sea");
        //this.debugFolder.close()

        this.debugFolder.add(this.material.uniforms.u_FlowFieldFrequency, 'value')
            .min(0).max(1).step(0.001).name('u_FlowfieldFrequency')
        this.debugFolder.add(this.material.uniforms.u_FlowFieldStrength, 'value')
            .min(0).max(100).step(0.001).name('u_FlowfieldStrength')
        this.debugFolder.add(this.material.uniforms.u_TimeScale, 'value')
            .min(0).max(2).step(0.001).name('u_TimeScale')

        // change this.material.color
        this.debugFolder.addColor(this.material, 'color')
            .name('Color')
            .onChange(() => {
                this.material.color.set(this.material.color)
            })


    }

    update( deltaTime ) {

        if(  this.material.uniforms ) {
            this.material.uniforms.u_Time.value = this.time.elapsed
        }
    }

}
