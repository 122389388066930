import * as THREE from 'three'
import Model from './Abstracts/Model.js'
import Experience from '../Experience.js'
import Debug from '../Utils/Debug.js'
import State from "../State.js";
import Materials from "../Materials/Materials.js";
import * as BufferGeometryUtils from "three/addons/utils/BufferGeometryUtils.js";
import gsap from "gsap";

import Particle from "./Particle.js";

export default class ParticlesHolder extends Model {
    experience = Experience.getInstance()
    debug = Debug.getInstance()
    state = State.getInstance()
    materials = Materials.getInstance()
    scene = experience.scene
    time = experience.time
    camera = experience.camera.instance
    renderer = experience.renderer.instance
    resources = experience.resources
    container = new THREE.Group();
    colors = this.state.colors

    particlesPool = this.state.game.particlesPool

    constructor() {
        super()

        this.setModel()
        this.setDebug()
    }

    setModel() {
        this.mesh = new THREE.Object3D();
        this.particlesInUse = [];
    }


    spawnParticles ( pos, density, color, scale ) {

        let nPArticles = density;
        for ( let i = 0; i < nPArticles; i++ ) {
            let particle;
            if ( this.particlesPool.length ) {
                particle = this.particlesPool.pop();
            } else {
                particle = new Particle();
            }
            this.mesh.add( particle.mesh );
            particle.mesh.visible = true;
            let _this = this;
            particle.mesh.position.y = pos.y;
            particle.mesh.position.x = pos.x;
            particle.explode( pos, color, scale );
        }
    }

    resize() {

    }

    setDebug() {
        if ( !this.debug.active ) return

    }

    update( deltaTime ) {

    }

}
