import './styles/index.scss';

import './preloader.js';

import Experience from './Experience/Experience.js';

const experience = new Experience(document.querySelector('canvas.webgl'));

const tooltipLabel = document.getElementById('tooltip-label');
const popupInfoStart = document.getElementById('popup-info-start');
const buttonInstruction = document.getElementById('button-how-to-play');
const popupInfo = document.getElementById('popup-info');
const buttonStart = document.getElementById('button-start');
const popupWinners = document.getElementById('popup-info-winners');
const popupEmail = document.getElementById('popup-info-send-email');
const popupSuccess = document.getElementById('popup-info-success');
const popupError = document.getElementById('popup-info-error');
const emailButtons = document.querySelectorAll(".js-button-email");

popupInfoStart.classList.remove('hidden');
buttonInstruction.addEventListener('click', function () {
    popupInfoStart.classList.add('hidden');
    popupInfo.classList.remove('hidden');
});

buttonStart.addEventListener('click', function () {
    popupInfo.classList.add('hidden');
    tooltipLabel.classList.remove('hidden');
    setTimeout(() => {
        tooltipLabel.classList.add('hidden');
    }, 10000);
    window.dispatchEvent(new CustomEvent('start-game'));
});


for (let i = 0; i < emailButtons.length; i++) {
    emailButtons[i].addEventListener("click", function() {
        popupWinners.classList.add('hidden');
        popupError.classList.add('hidden');
        popupEmail.classList.remove('hidden');
    });
}

document.getElementById('user-email-form').addEventListener('submit', function(event) {
    event.preventDefault();

    const email = document.getElementById('user-email').value;
		const currentButton = document.querySelector('[data-send-email-button]');

		if (currentButton.dataset.disabled === true) {
				return;
		};

		currentButton.dataset.disabled = true;
		fetch(`${window.location.protocol}//api.${window.location.host}/v1/promo_codes`, {
				method: 'POST',
				headers: {
						'Content-Type': 'application/json',
				},
				body: JSON.stringify({ email: email })
		})
				.then(async response => {
						if (response.status === 200) {
								popupEmail.classList.add('hidden');
								popupSuccess.classList.remove('hidden');
								setTimeout(() => {
										if (popupSuccess.classList.contains('hidden')) {
												return;
										}
										popupSuccess.classList.add('hidden');
										window.dispatchEvent(new CustomEvent('replay-game'));
								}, 5000);
						} else {
								popupEmail.classList.add('hidden');
								popupError.classList.remove('hidden');
						}
				})
				.catch(error => {
						popupEmail.classList.add('hidden');
						popupError.classList.remove('hidden');
				})
				.finally(() => {
						currentButton.dataset.disabled = true;
				});
});
