#define LAMBERT

uniform vec3 diffuse;
uniform vec3 emissive;
uniform float opacity;
uniform sampler2D u_BackgroundTexture;
uniform vec2 u_Resolution;

#include <common>
#include <packing>
#include <dithering_pars_fragment>
#include <color_pars_fragment>
#include <uv_pars_fragment>
#include <map_pars_fragment>
#include <alphamap_pars_fragment>
#include <alphatest_pars_fragment>
#include <alphahash_pars_fragment>
#include <aomap_pars_fragment>
#include <lightmap_pars_fragment>
#include <emissivemap_pars_fragment>
#include <envmap_common_pars_fragment>
#include <envmap_pars_fragment>
#include <fog_pars_fragment>
#include <bsdfs>
#include <lights_pars_begin>
#include <normal_pars_fragment>
#include <lights_lambert_pars_fragment>
#include <shadowmap_pars_fragment>
#include <bumpmap_pars_fragment>
#include <normalmap_pars_fragment>
#include <specularmap_pars_fragment>
#include <logdepthbuf_pars_fragment>
#include <clipping_planes_pars_fragment>

void main() {

    vec4 diffuseColor = vec4( diffuse, opacity );
    #include <clipping_planes_fragment>

    ReflectedLight reflectedLight = ReflectedLight( vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ) );
    vec3 totalEmissiveRadiance = emissive;

    #include <logdepthbuf_fragment>
    #include <map_fragment>
    #include <color_fragment>

    // custom fix color from the original design
    vec2 screenUV = gl_FragCoord.xy / u_Resolution;
    vec4 backgroundTexture = texture2D( u_BackgroundTexture, screenUV );
    diffuseColor.b += 0.4;
    //diffuseColor.rgb += backgroundTexture.rgb;
    //diffuseColor.rgb -= 0.6;


    #include <alphamap_fragment>
    #include <alphatest_fragment>
    #include <alphahash_fragment>
    #include <specularmap_fragment>
    #include <normal_fragment_begin>
    #include <normal_fragment_maps>
    #include <emissivemap_fragment>

    // accumulation
    #include <lights_lambert_fragment>
    #include <lights_fragment_begin>
    #include <lights_fragment_maps>
    #include <lights_fragment_end>

    // modulation
    #include <aomap_fragment>

    vec3 outgoingLight = reflectedLight.directDiffuse + reflectedLight.indirectDiffuse + totalEmissiveRadiance;

    #include <envmap_fragment>
    #include <opaque_fragment>
    #include <tonemapping_fragment>
    #include <colorspace_fragment>

    //#include <fog_fragment>
    #ifdef USE_FOG

        #ifdef FOG_EXP2

        float fogFactor = 1.0 - exp( - fogDensity * fogDensity * vFogDepth * vFogDepth );

        #else

        float fogFactor = smoothstep( fogNear, fogFar, vFogDepth );

        #endif

        vec3 _fogColor = fogColor;
        _fogColor.g += 0.25;

        gl_FragColor.rgb = mix( gl_FragColor.rgb, _fogColor, smoothstep(0.1, 1.35, backgroundTexture.r) - 0.5 );
        gl_FragColor.rgb = mix( gl_FragColor.rgb, fogColor, fogFactor + 0.1 );

    #endif

    #include <premultiplied_alpha_fragment>
    #include <dithering_fragment>

}
