import * as THREE from 'three'
import Model from './Abstracts/Model.js'
import Experience from '../Experience.js'
import Debug from '../Utils/Debug.js'
import State from "../State.js";
import Materials from "../Materials/Materials.js";
import * as BufferGeometryUtils from "three/addons/utils/BufferGeometryUtils.js";
import gsap from "gsap";

export default class Particle extends Model {
    experience = Experience.getInstance()
    debug = Debug.getInstance()
    state = State.getInstance()
    materials = Materials.getInstance()
    scene = experience.scene
    time = experience.time
    camera = experience.camera.instance
    renderer = experience.renderer.instance
    resources = experience.resources
    container = new THREE.Group();
    colors = this.state.colors

    particlesPool = this.state.game.particlesPool

    constructor() {
        super()

        this.setModel()
        this.setDebug()
    }

    setModel() {
        const geom = new THREE.TetrahedronGeometry( 3, 0 );
        const mat = new THREE.MeshPhongMaterial( {
            color: 0x009999,
            shininess: 0,
            specular: 0xffffff,
            flatShading: true
        } );
        this.mesh = new THREE.Mesh( geom, mat );
    }

    explode( pos, color, scale ) {
        let _this = this;
        let _p = this.mesh.parent;
        this.mesh.material.color = new THREE.Color( color );
        this.mesh.material.needsUpdate = true;
        this.mesh.scale.set( scale, scale, scale );
        var targetX = pos.x + ( -1 + Math.random() * 2 ) * 50;
        var targetY = pos.y + ( -1 + Math.random() * 2 ) * 50;
        var speed = .6 + Math.random() * .2;
        gsap.to( this.mesh.rotation, speed, { x: Math.random() * 12, y: Math.random() * 12 } );
        gsap.to( this.mesh.scale, speed, { x: .1, y: .1, z: .1 } );
        gsap.to( this.mesh.position, speed, {
            x: targetX, y: targetY, delay: Math.random() * .1, ease: "Power2.easeOut", onComplete: () => {
                if ( _p ) _p.remove( _this.mesh );
                _this.mesh.scale.set( 1, 1, 1 );
                this.particlesPool.unshift( _this );
            }
        } );
    }

    resize() {

    }

    setDebug() {
        if ( !this.debug.active ) return

    }

    update( deltaTime ) {

    }

}
