import * as THREE from 'three'
import Model from './Abstracts/Model.js'
import Experience from '../Experience.js'
import Debug from '../Utils/Debug.js'
import State from "../State.js";
import Materials from "../Materials/Materials.js";
import * as BufferGeometryUtils from "three/addons/utils/BufferGeometryUtils.js";

export default class Enemy extends Model {
    experience = Experience.getInstance()
    debug = Debug.getInstance()
    state = State.getInstance()
    materials = Materials.getInstance()
    scene = experience.scene
    time = experience.time
    camera = experience.camera.instance
    renderer = experience.renderer.instance
    resources = experience.resources
    container = new THREE.Group();
    colors = this.state.colors

    constructor() {
        super()

        this.setModel()
        this.setDebug()
    }

    setModel() {
        let model = this.model = this.resources.items.flyingBallModel.scene
        model = model.clone()
        model.scale.setScalar( 25 )

        let propeller = new THREE.Object3D()

        model.traverse( child => {
                if ( child.isMesh ) {

                    if ( child.name === 'NGon001' ) {
                        propeller = child
                    }

                    child.castShadow = true
                    child.material.metalness = 0
                    child.material.roughness = 1.0
                    child.material.specular = 0
                    child.material.needsUpdate = true
                }
            }
        )


        // const geom = new THREE.TetrahedronGeometry( 8, 2 );
        // const mat = new THREE.MeshPhongMaterial( {
        //     color: this.colors.red,
        //     shininess: 0,
        //     specular: 0xffffff,
        //     flatShading: true
        // } );
        //this.mesh = new THREE.Mesh( geom, mat );

        this.mesh = model
        this.mesh.propeller = propeller

        //this.mesh.castShadow = true;
        this.angle = 0;
        this.dist = 0;
    }

    resize() {

    }

    setDebug() {
        if ( !this.debug.active ) return

    }

    update( deltaTime ) {

    }

}
