import * as THREE from 'three'
import Model from './Abstracts/Model.js'
import Experience from '../Experience.js'
import Debug from '../Utils/Debug.js'
import State from "../State.js";
import Materials from "../Materials/Materials.js";
import * as BufferGeometryUtils from "three/addons/utils/BufferGeometryUtils.js";

import Coin from "@experience/World/Coin.js";

export default class CoinsHolder extends Model {
    experience = Experience.getInstance()
    debug = Debug.getInstance()
    state = State.getInstance()
    materials = Materials.getInstance()
    scene = experience.scene
    time = experience.time
    camera = experience.camera.instance
    renderer = experience.renderer.instance
    resources = experience.resources
    container = new THREE.Group();
    colors = this.state.colors



    constructor( nCoins ) {
        super()

        this.nCoins = nCoins

        this.setModel()
        this.setDebug()
    }

    postInit() {
        this.airplane = this.experience.world.game.airplane
        this.particlesHolder = this.experience.world.game.particlesHolder
    }

    setModel() {
        this.mesh = new THREE.Object3D();
        this.coinsInUse = [];
        this.coinsPool = [];
        for ( let i = 0; i < this.nCoins; i++ ) {
            const coin = new Coin();
            this.coinsPool.push( coin );
        }
    }

    spawnCoins() {
        var nCoins = 1 + Math.floor( Math.random() * 10 );
        var d = this.state.game.seaRadius + this.state.game.planeDefaultHeight + ( -1 + Math.random() * 2 ) * ( this.state.game.planeAmpHeight - 20 );
        var amplitude = 10 + Math.round( Math.random() * 10 );
        for ( var i = 0; i < nCoins; i++ ) {
            var coin;
            if ( this.coinsPool.length ) {
                coin = this.coinsPool.pop();
            } else {
                coin = new Coin();
            }
            this.mesh.add( coin.mesh );
            this.coinsInUse.push( coin );
            coin.angle = -( i * 0.02 );
            coin.distance = d + Math.cos( i * .5 ) * amplitude;
            coin.mesh.position.y = -this.state.game.seaRadius + Math.sin( coin.angle ) * coin.distance;
            coin.mesh.position.x = Math.cos( coin.angle ) * coin.distance;
        }
    }

    rotateCoins() {
        for ( var i = 0; i < this.coinsInUse.length; i++ ) {
            var coin = this.coinsInUse[ i ];
            if ( coin.exploding ) continue;
            coin.angle += this.state.game.speed * this.time.delta * this.state.game.coinsSpeed;
            if ( coin.angle > Math.PI * 2 ) coin.angle -= Math.PI * 2;
            coin.mesh.position.y = -this.state.game.seaRadius + Math.sin( coin.angle ) * coin.distance;
            coin.mesh.position.x = Math.cos( coin.angle ) * coin.distance;
            coin.mesh.rotation.z += Math.random() * .01;
            coin.mesh.rotation.y += Math.random() * .01;

            //var globalCoinPosition =  coin.mesh.localToWorld(new THREE.Vector3());
            let diffPos = this.airplane.mesh.position.clone().sub( coin.mesh.position.clone() );
            let d = diffPos.length();
            if ( d < this.state.game.coinDistanceTolerance ) {
                this.coinsPool.unshift( this.coinsInUse.splice( i, 1 )[ 0 ] );
                this.mesh.remove( coin.mesh );
                this.particlesHolder.spawnParticles( coin.mesh.position.clone(), 5, 0x009999, .8 );
                this.addEnergy();
                i--;
            } else if ( coin.angle > Math.PI ) {
                this.coinsPool.unshift( this.coinsInUse.splice( i, 1 )[ 0 ] );
                this.mesh.remove( coin.mesh );
                i--;
            }
        }
    }

    addEnergy() {
        this.state.game.energy += this.state.game.coinValue;
        this.state.game.energy = Math.min( this.state.game.energy, 100 );
    }

    resize() {

    }

    setDebug() {
        if ( !this.debug.active ) return

    }

    update( deltaTime ) {

    }

}
