import * as THREE from 'three'
import Model from './Abstracts/Model.js'
import Experience from '../Experience.js'
import Debug from '../Utils/Debug.js'
import State from "../State.js";
import Materials from "../Materials/Materials.js";
import * as BufferGeometryUtils from "three/addons/utils/BufferGeometryUtils.js";

import Cloud from "./Cloud.js";

export default class Sky extends Model {
    experience = Experience.getInstance()
    debug = Debug.getInstance()
    state = State.getInstance()
    materials = Materials.getInstance()
    scene = experience.scene
    time = experience.time
    camera = experience.camera.instance
    renderer = experience.renderer.instance
    resources = experience.resources
    container = new THREE.Group();
    colors = this.state.colors

    constructor() {
        super()

        this.setModel()
        this.setDebug()
    }

    setModel() {
        this.mesh = new THREE.Object3D();
        this.nClouds = 20;
        this.clouds = [];
        var stepAngle = Math.PI * 2 / this.nClouds;
        for ( var i = 0; i < this.nClouds; i++ ) {
            var c = new Cloud();
            this.clouds.push( c );
            var a = stepAngle * i;
            var h = this.state.game.seaRadius + 150 + Math.random() * 200;
            c.mesh.position.y = Math.sin( a ) * h;
            c.mesh.position.x = Math.cos( a ) * h;
            c.mesh.position.z = -300 - Math.random() * 500;
            c.mesh.rotation.z = a + Math.PI / 2;
            var s = 1 + Math.random() * 2;
            c.mesh.scale.set( s, s, s );
            this.mesh.add( c.mesh );
        }
    }

    moveClouds() {
        for ( var i = 0; i < this.nClouds; i++ ) {
            var c = this.clouds[ i ];
            c.rotate();
        }

        this.mesh.rotation.z += this.state.game.speed * this.time.delta;
    }

    resize() {

    }

    setDebug() {
        if ( !this.debug.active ) return

    }

    update( deltaTime ) {

    }

}
