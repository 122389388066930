#define LAMBERT

varying vec3 vViewPosition;

#include <common>
#include <batching_pars_vertex>
#include <uv_pars_vertex>
#include <displacementmap_pars_vertex>
#include <envmap_pars_vertex>
#include <color_pars_vertex>
#include <fog_pars_vertex>
#include <normal_pars_vertex>
#include <morphtarget_pars_vertex>
#include <skinning_pars_vertex>
#include <shadowmap_pars_vertex>
#include <logdepthbuf_pars_vertex>
#include <clipping_planes_pars_vertex>

// ** custom ** //

uniform float u_Time;
uniform float u_FlowFieldFrequency;
uniform float u_FlowFieldStrength;
uniform float u_TimeScale;

#include ../Includes/simplexNoise3d.glsl
#include ../Includes/simplexNoise4d.glsl

vec3 waves( vec3 position, float time )
{
    return vec3(
    simplexNoise4d(vec4(position.xyz * u_FlowFieldFrequency + 0.0, time)),
    simplexNoise4d(vec4(position.xyz * u_FlowFieldFrequency + 1.0, time)),
    simplexNoise4d(vec4(position.xyz * u_FlowFieldFrequency + 2.0, time))
    );
}

void main() {

    #include <uv_vertex>
    #include <color_vertex>
    #include <morphinstance_vertex>
    #include <morphcolor_vertex>
    #include <batching_vertex>

    #include <beginnormal_vertex>

    float time = u_Time * u_TimeScale;

    vec3 biTangent = cross(normal, tangent.xyz);
    float shift = 0.1;

    vec3 positionA = position + tangent.xyz * shift;
    vec3 positionB = position + biTangent * shift;

    positionA = positionA + waves(positionA, time) * u_FlowFieldStrength;
    positionB = positionB + waves(positionB, time) * u_FlowFieldStrength;
    vec3 pos = position + waves(position, time) * u_FlowFieldStrength;

    // Compute normal
    vec3 toA = normalize(positionA - pos);
    vec3 toB = normalize(positionB - pos);
    objectNormal = cross(toA, toB);

    objectTangent = toA;

    #include <morphnormal_vertex>
    #include <skinbase_vertex>
    #include <skinnormal_vertex>
    #include <defaultnormal_vertex>
    #include <normal_vertex>

    #include <begin_vertex>

    transformed.xyz = pos;

    #include <morphtarget_vertex>
    #include <skinning_vertex>
    #include <displacementmap_vertex>
    #include <project_vertex>
    #include <logdepthbuf_vertex>
    #include <clipping_planes_vertex>

    vViewPosition = - mvPosition.xyz;

    #include <worldpos_vertex>
    #include <envmap_vertex>
    #include <shadowmap_vertex>
    #include <fog_vertex>

}
