import * as THREE from 'three'
import Model from './Abstracts/Model.js'
import Experience from '../Experience.js'
import Debug from '../Utils/Debug.js'
import State from "../State.js";
import Materials from "../Materials/Materials.js";
import * as BufferGeometryUtils from 'three/addons/utils/BufferGeometryUtils.js';
import gsap from "gsap";
import * as MathHelper from '../Utils/MathHelper.js';
import { MathUtils } from "three";
import AirPlane from './AirPlane.js';
import Sea from './Sea.js';
import Sky from './Sky.js';
import CoinsHolder from './CoinsHolder.js';
import Enemy from './Enemy.js';
import EnemiesHolder from './EnemiesHolder.js';

import Particle from './Particle.js';
import ParticlesHolder from './ParticlesHolder.js';

export default class Game extends Model {
    experience = Experience.getInstance()
    debug = Debug.getInstance()
    state = State.getInstance()
    materials = Materials.getInstance()
    sizes = this.experience.sizes
    scene = experience.scene
    time = experience.time
    camera = experience.camera.instance
    renderer = experience.renderer.instance
    resources = experience.resources
    container = new THREE.Group();

    isMobile = this.experience.isMobile
    cameraFovMin = 40
    cameraFovMax = 80

    constructor() {
        super()

        this.deviceLogic()
        this.init()

        this.setModel()
        this.setDebug()
    }

    fieldDistance
    energyBar
    replayMessage
    losersPopup
    winnersPopup
    fieldLevel
    levelCircle;

    game

    ambientLight
    hemisphereLight
    shadowLight

    sea
    airplane

    mousePos = { x: 0, y: 0 }
    mousePosTarget = { x: 0, y: 0 }

    deltaTime = 0;
    newTime = new Date().getTime();
    oldTime = new Date().getTime();
    enemiesPool = [];
    particlesPool = [];
    particlesInUse = [];

    deviceLogic() {
        if( window.innerHeight > window.innerWidth ){
            this.cameraFovMin = 80
            this.cameraFovMax = 120
        } else {
            this.cameraFovMin = 40
            this.cameraFovMax = 80
        }
    }

    init() {

        this.fieldDistance = document.getElementById( "distValue" );
        this.energyBar = document.getElementById( "energyBar" );
        this.replayMessage = document.getElementById( "replayMessage" );
        this.losersPopup = document.getElementById( "popup-info-losers" );
        this.winnersPopup = document.getElementById( "popup-info-winners" );
				this.startPopup = document.getElementById('popup-info-start');
        this.fieldLevel = document.getElementById( "levelValue" );
        this.levelCircle = document.getElementById( "levelCircleStroke" );

				this.winnersPopupTimeout;

        this.resetGame("waitingReplay");
        this.createScene();
        //
        this.createLights();
        this.createPlane();
        this.createSea();
        this.createSky();
        this.createCoins();
        this.createEnemies();
        this.createParticles();
        //

        this.setEvents();
    }

    postInit() {
        this.coinsHolder?.postInit()
        this.enemiesHolder?.postInit()
    }

    resetGame(status) {
        this.game = this.state.game = {
            speed: 0,
            initSpeed: .35,
            baseSpeed: .35,
            targetBaseSpeed: .35,
            incrementSpeedByTime: .025,
            incrementSpeedByLevel: .05,
            distanceForSpeedUpdate: 100,
            speedLastUpdate: 0,

            startTime: performance.now(),
            elapsedTime: 0,
            distance: 0,
            ratioSpeedDistance: 50,
            energy: 100,
            ratioSpeedEnergy: 3,

            level: 1,
            levelLastUpdate: 0,
            distanceForLevelUpdate: 1000,

            planeDefaultHeight: 250,
            planeAmpHeight: 80,
            planeAmpWidth: 75,
            planeMoveSensivity: 5,
            planeRotXSensivity: 0.8,
            planeRotZSensivity: 0.4,
            planeFallSpeed: 1,
            planeMinSpeed: 1.2,
            planeMaxSpeed: 1.6,
            planeSpeed: 0,
            planeCollisionDisplacementX: 0,
            planeCollisionSpeedX: 0,

            planeCollisionDisplacementY: 0,
            planeCollisionSpeedY: 0,

            seaRadius: 900,
            seaLength: 800,
            //seaRotationSpeed:0.006,
            wavesMinAmp: 5,
            wavesMaxAmp: 20,
            wavesMinSpeed: 0.001,
            wavesMaxSpeed: 0.003,

            cameraFarPos: 500,
            cameraNearPos: 150,
            cameraSensivity: 2,

            coinDistanceTolerance: 25,
            coinValue: 3,
            coinsSpeed: .5,
            coinLastSpawn: 0,
            distanceForCoinsSpawn: 100,

            enemyDistanceTolerance: 23,
            enemyValue: 10,
            enemiesSpeed: .6,
            enemyLastSpawn: 0,
            distanceForEnemiesSpawn: 50,

            status: status,

            enemiesPool: [],
            particlesPool: [],
            particlesInUse: []
        };
        this.fieldLevel.innerHTML = Math.floor( this.game.level );
    }

    createScene() {
        this.scene.fog = new THREE.Fog( 0xebd14a, 300, 950 );
        this.camera.position.x = 0;
        this.camera.position.z = 200;
        this.camera.position.y = this.game.planeDefaultHeight;
        //camera.lookAt(new THREE.Vector3(0, 400, 0));


        this.renderer.shadowMap.enabled = true;
    }

    createLights() {

        this.hemisphereLight = new THREE.HemisphereLight( 0xaaaaaa, 0x000000, .9 )

        this.ambientLight = new THREE.AmbientLight( 0xdc8874, .5 );

        this.shadowLight = new THREE.DirectionalLight( 0xffffff, .9 );
        this.shadowLight.position.set( 150, 350, 350 );
        this.shadowLight.castShadow = true;
        this.shadowLight.shadow.camera.left = -400;
        this.shadowLight.shadow.camera.right = 400;
        this.shadowLight.shadow.camera.top = 400;
        this.shadowLight.shadow.camera.bottom = -400;
        this.shadowLight.shadow.camera.near = 1;
        this.shadowLight.shadow.camera.far = 1000;
        this.shadowLight.shadow.mapSize.width = 4096;
        this.shadowLight.shadow.mapSize.height = 4096;

        var ch = new THREE.CameraHelper( this.shadowLight.shadow.camera );

        //this.scene.add(ch);
        this.scene.add( this.hemisphereLight );
        this.scene.add( this.shadowLight );
        this.scene.add( this.ambientLight );

    }

    createPlane() {
        this.airplane = new AirPlane();
        this.airplane.mesh.scale.set( .25, .25, .25 );
        this.airplane.mesh.position.y = this.game.planeDefaultHeight;
        this.scene.add( this.airplane.mesh );
    }

    createSea() {
        this.sea = new Sea();
        this.sea.mesh.position.y = -this.game.seaRadius;
        this.scene.add( this.sea.mesh );
    }

    createSky() {
        this.sky = new Sky();
        this.sky.mesh.position.y = -this.game.seaRadius;
        this.scene.add( this.sky.mesh );
    }

    createCoins() {
        this.coinsHolder = new CoinsHolder( 20 );
        this.scene.add( this.coinsHolder.mesh )
    }

    createEnemies(){
        for ( let i = 0; i < 10; i++ ) {
            const enemy = new Enemy();
            this.state.game.enemiesPool.push( enemy );
        }
        this.enemiesHolder = new EnemiesHolder();
        //enemiesHolder.mesh.position.y = -game.seaRadius;
        this.scene.add( this.enemiesHolder.mesh )
    }

    createParticles() {
        for ( let i = 0; i < 10; i++ ) {
            let particle = new Particle();
            this.state.game.particlesPool.push( particle );
        }
        this.particlesHolder = new ParticlesHolder();
        //enemiesHolder.mesh.position.y = -game.seaRadius;
        this.scene.add( this.particlesHolder.mesh )
    }

    setEventsFunctions() {
        //if( !this.isMobile ) {
            this.handleMouseMove = ( event ) => {
                const tx = -1 + ( event.clientX / this.sizes.width ) * 2;
                const ty = 1 - ( event.clientY / this.sizes.height ) * 2;
                this.mousePos = { x: tx, y: ty };

                if( this.isMobile ) {
                    this.mousePos.x = 0
                }
            }

            this.handleTouchMove = ( event ) => {
                event.preventDefault();
                var tx = -1 + ( event.touches[ 0 ].pageX / this.sizes.width ) * 2;
                var ty = 1 - ( event.touches[ 0 ].pageY / this.sizes.height ) * 2;
                this.mousePos = { x: tx, y: ty };

                if( this.isMobile ) {
                    this.mousePos.x = 0
                }
            }
        //}

        this.handleMouseUp = ( event ) => {
            if ( this.state.game.status === "waitingReplay" && event.target === document.getElementById("button-start") ) {
                this.resetGame("playing");
                this.hideReplay();
                this.hideLosersPopup();
                this.hideWinnersPopup();
            } else if ( this.state.game.status === "waitingReplay" && event.target === document.getElementById("button-restart") ) {
								this.hideLosersPopup();
								window.dispatchEvent(new CustomEvent('replay-game'));
						}
        }

        this.handleTouchEnd = ( event ) => {
            if ( this.state.game.status === "waitingReplay" && event.target === document.getElementById("button-start") ) {
                this.resetGame("playing");
                this.hideReplay();
                this.hideLosersPopup();
                this.hideWinnersPopup();
            } else if ( this.state.game.status === "waitingReplay" && event.target === document.getElementById("button-restart") ) {
								this.hideLosersPopup();
								window.dispatchEvent(new CustomEvent('replay-game'));
						}
        }

        this.handleStartGame = () => {
            this.resetGame("playing");
        }

				this.handleReplayGame = () => {
						this.resetGame("waitingReplay");
						this.showStartPopup();
				}
    }

    setEvents() {
        this.setEventsFunctions()

        document.addEventListener( 'mousemove', this.handleMouseMove, false );
        document.addEventListener( 'touchmove', this.handleTouchMove, false );
        document.addEventListener( 'mouseup', this.handleMouseUp, false );
        document.addEventListener( 'touchend', this.handleTouchEnd, false );

        window.addEventListener('start-game', this.handleStartGame);
        window.addEventListener('replay-game', this.handleReplayGame);
    }

    showReplay(){
        this.replayMessage.style.display="block";
    }

    hideReplay(){
        this.replayMessage.style.display="none";
    }

    showLosersPopup() {
        this.losersPopup.classList.remove('hidden');
    }

    showWinnersPopup() {
        this.winnersPopup.classList.remove('hidden');
				this.winnersPopupTimeout = setTimeout(() => {
						if (this.winnersPopup.classList.contains('hidden')) {
								return;
						}
						this.hideWinnersPopup();
						window.dispatchEvent(new CustomEvent('replay-game'));
				}, 20000);
    }

		showStartPopup() {
				this.startPopup.classList.remove('hidden');
		}

    hideLosersPopup() {
        this.losersPopup.classList.add('hidden');
    }

    hideWinnersPopup() {
        this.winnersPopup.classList.add('hidden');
				clearTimeout(this.winnersPopupTimeout);
    }

    setModel() {

        // this.container.add( cube )
        // this.scene.add( this.container )
    }

    updatePlane() {
        const game = this.state.game

        game.planeSpeed = MathHelper.normalize( this.mousePos.x, -.5, .5, game.planeMinSpeed, game.planeMaxSpeed );
        let targetY = MathHelper.normalize( this.mousePos.y, -.75, .75, game.planeDefaultHeight - game.planeAmpHeight, game.planeDefaultHeight + game.planeAmpHeight );
        let targetX = MathHelper.normalize( this.mousePos.x, -1, 1, -game.planeAmpWidth * .7, -game.planeAmpWidth );

        game.planeCollisionDisplacementX += game.planeCollisionSpeedX;
        targetX += game.planeCollisionDisplacementX;


        game.planeCollisionDisplacementY += game.planeCollisionSpeedY;
        targetY += game.planeCollisionDisplacementY;

        this.airplane.mesh.position.y += ( targetY - this.airplane.mesh.position.y ) * this.time.delta * game.planeMoveSensivity;
        this.airplane.mesh.position.x += ( targetX - this.airplane.mesh.position.x ) * this.time.delta * game.planeMoveSensivity;

        this.airplane.mesh.rotationTarget = {}
        this.airplane.mesh.rotationTarget.z = ( targetY - this.airplane.mesh.position.y ) * this.time.delta * game.planeRotXSensivity * 2;
        this.airplane.mesh.rotationTarget.x = ( this.airplane.mesh.position.y - targetY ) * this.time.delta * game.planeRotZSensivity * 4.;

        this.airplane.mesh.rotation.z = MathUtils.damp( this.airplane.mesh.rotation.z, this.airplane.mesh.rotationTarget.z, 9, this.time.deltaTime )
        this.airplane.mesh.rotation.x = MathUtils.damp( this.airplane.mesh.rotation.x, this.airplane.mesh.rotationTarget.x, 9, this.time.deltaTime )

        // this.airplane.mesh.rotation.z = ( targetY - this.airplane.mesh.position.y ) * this.time.delta * game.planeRotXSensivity;
        // this.airplane.mesh.rotation.x = ( this.airplane.mesh.position.y - targetY ) * this.time.delta * game.planeRotZSensivity;
        let targetCameraZ = MathHelper.normalize( game.planeSpeed, game.planeMinSpeed, game.planeMaxSpeed, game.cameraNearPos, game.cameraFarPos );
        this.camera.fov = MathHelper.normalize( this.mousePos.x, -1, 1, this.cameraFovMin, this.cameraFovMax );
        this.camera.updateProjectionMatrix()
        this.camera.position.y += ( this.airplane.mesh.position.y - this.camera.position.y ) * this.time.delta * game.cameraSensivity;

        game.planeCollisionSpeedX += ( 0 - game.planeCollisionSpeedX ) * this.time.delta * 30;
        game.planeCollisionDisplacementX += ( 0 - game.planeCollisionDisplacementX ) * this.time.delta * 10;
        game.planeCollisionSpeedY += ( 0 - game.planeCollisionSpeedY ) * this.time.delta * 30;
        game.planeCollisionDisplacementY += ( 0 - game.planeCollisionDisplacementY ) * this.time.delta * 10;

        //this.airplane.pilot.updateHairs();
    }

    updateDistance() {
        const game = this.state.game
        game.distance += game.speed * this.time.delta * game.ratioSpeedDistance;
        this.fieldDistance.innerHTML = Math.floor( game.distance );
        let d = 502 * ( 1 - ( game.distance % game.distanceForLevelUpdate ) / game.distanceForLevelUpdate );
        this.levelCircle.setAttribute( "stroke-dashoffset", d );

        if ( game.distance >= 3000 ) {
            game.status = "gamewin";
        }
    }

    updateEnergy() {
        const game = this.state.game
        game.energy -= game.speed * this.time.delta * game.ratioSpeedEnergy;
        game.energy = Math.max( 0, game.energy );
        this.energyBar.style.right = ( 100 - game.energy ) + "%";
        this.energyBar.style.backgroundColor = ( game.energy < 50 ) ? "#f25346" : "#0198fe";

        if ( game.energy < 30 ) {
            this.energyBar.style.animationName = "blinking";
        } else {
            this.energyBar.style.animationName = "none";
        }

        if ( game.energy < 1 ) {
            game.status = "gameover";
        }
    }

    updateTime(){
        const game = this.state.game
        const currentTime = performance.now();
        this.game.elapsedTime = Math.round((currentTime - this.game.startTime) * 0.001) // seconds
        const minutes = this.game.elapsedTime / 60

        if (game.status === 'playing' && minutes >= 4.0) {
            game.status = 'gameover'
        }
    }

    resize() {
        this.deviceLogic()
    }

    setDebug() {
        if ( !this.debug.active ) return

        //this.debug.createDebugTexture( this.resources.items.displacementTexture )
    }

    update( deltaTime ) {
        this.newTime = this.time.delta

        const game = this.state.game

        if ( this.state.game.status === "playing" ) {

            // Add energy coins every 100m;
            if ( Math.floor( game.distance ) % game.distanceForCoinsSpawn == 0 && Math.floor( game.distance ) > game.coinLastSpawn ) {
                game.coinLastSpawn = Math.floor( game.distance );
                this.coinsHolder.spawnCoins();
            }

            if ( Math.floor( game.distance ) % game.distanceForSpeedUpdate == 0 && Math.floor( game.distance ) > game.speedLastUpdate ) {
                game.speedLastUpdate = Math.floor( game.distance );
                game.targetBaseSpeed += game.incrementSpeedByTime * deltaTime;
            }

            if ( Math.floor( game.distance ) % game.distanceForEnemiesSpawn == 0 && Math.floor( game.distance ) > game.enemyLastSpawn ) {
                game.enemyLastSpawn = Math.floor( game.distance );
                this.enemiesHolder.spawnEnemies();
            }

            if ( Math.floor( game.distance ) % game.distanceForLevelUpdate == 0 && Math.floor( game.distance ) > game.levelLastUpdate ) {
                game.levelLastUpdate = Math.floor( game.distance );
                game.level++;
                this.fieldLevel.innerHTML = Math.floor( game.level );

                game.targetBaseSpeed = game.initSpeed + game.incrementSpeedByLevel * game.level
            }

            this.updatePlane()
            this.updateDistance();
            this.updateEnergy();
            game.baseSpeed += ( game.targetBaseSpeed - game.baseSpeed ) * deltaTime * 20;
            game.speed = game.baseSpeed * game.planeSpeed;
        } else if ( game.status === "gameover" ) {
            game.speed *= .99;
            this.airplane.mesh.rotation.z += ( -Math.PI / 2 - this.airplane.mesh.rotation.z ) * .2 * deltaTime;
            this.airplane.mesh.rotation.x += 0.3 * deltaTime;
            game.planeFallSpeed *= 1.05;
            this.airplane.mesh.position.y -= game.planeFallSpeed * deltaTime;

            if ( this.airplane.mesh.position.y < -200 ) {
                //this.showReplay();
                this.showLosersPopup();
                game.status = "waitingReplay";

            }
        } else if ( game.status === "gamewin" ) {
            this.showWinnersPopup();
            game.status = "waitingReplay";
        } else if ( game.status === "waitingReplay" ) {

        }


        //this.airplane.propeller.rotation.x += .2 + this.state.game.planeSpeed * deltaTime * .005;
        this.sea.mesh.rotation.z += this.state.game.speed * deltaTime;//*game.seaRotationSpeed;

        if ( this.sea.mesh.rotation.z > 2 * Math.PI ) this.sea.mesh.rotation.z -= 2 * Math.PI;

        this.ambientLight.intensity += ( .5 - this.ambientLight.intensity ) * deltaTime * 5;

        this.coinsHolder.rotateCoins();
        this.enemiesHolder.rotateEnemies();

        this.sky.moveClouds();
        //this.sea.moveWaves();

        this.sea.update( deltaTime )
        this.enemiesHolder.update( deltaTime )
        this.updateTime()
    }
}
