import * as THREE from 'three'
import Model from './Abstracts/Model.js'
import Experience from '../Experience.js'
import Debug from '../Utils/Debug.js'
import State from "../State.js";
import Materials from "../Materials/Materials.js";
import * as BufferGeometryUtils from "three/addons/utils/BufferGeometryUtils.js";
import Enemy from "./Enemy.js";

export default class EnemiesHolder extends Model {
    experience = Experience.getInstance()
    debug = Debug.getInstance()
    state = State.getInstance()
    materials = Materials.getInstance()
    scene = experience.scene
    time = experience.time
    camera = experience.camera.instance
    renderer = experience.renderer.instance
    resources = experience.resources
    container = new THREE.Group();
    colors = this.state.colors

    enemiesPool = this.state.game.enemiesPool

    constructor() {
        super()

        this.setModel()
        this.setDebug()
    }

    setModel() {
        this.mesh = new THREE.Object3D();
        this.enemiesInUse = [];
    }

    postInit() {
        this.airplane = this.experience.world.game.airplane
        this.particlesHolder = this.experience.world.game.particlesHolder
        this.ambientLight = this.experience.world.game.ambientLight
    }

    spawnEnemies() {
        let nEnemies = this.state.game.level;

        for ( var i = 0; i < nEnemies; i++ ) {
            var enemy;
            if ( this.enemiesPool.length ) {
                enemy = this.enemiesPool.pop();
            } else {
                enemy = new Enemy();
            }

            enemy.angle = -( i * 0.1 );
            enemy.distance = this.state.game.seaRadius + this.state.game.planeDefaultHeight + ( -1 + Math.random() * 2 ) * ( this.state.game.planeAmpHeight - 20 );
            enemy.mesh.position.y = -this.state.game.seaRadius + Math.sin( enemy.angle ) * enemy.distance;
            enemy.mesh.position.x = Math.cos( enemy.angle ) * enemy.distance;

            this.mesh.add( enemy.mesh );
            this.enemiesInUse.push( enemy );
        }
    }

    rotateEnemies() {
        for ( let i = 0; i < this.enemiesInUse.length; i++ ) {
            let enemy = this.enemiesInUse[ i ];
            enemy.angle += this.state.game.speed * this.time.delta * this.state.game.enemiesSpeed;

            if ( enemy.angle > Math.PI * 2 ) enemy.angle -= Math.PI * 2;

            enemy.mesh.position.y = -this.state.game.seaRadius + Math.sin( enemy.angle ) * enemy.distance;
            enemy.mesh.position.y += Math.sin( this.time.elapsed * 5 ) * 25;
            enemy.mesh.position.x = Math.cos( enemy.angle ) * enemy.distance;
            //enemy.mesh.rotation.z += Math.random() * .01;
            //enemy.mesh.rotation.y += Math.random() * .01;
            enemy.mesh.rotation.z += Math.sin( this.time.elapsed ) * 0.001;
            enemy.mesh.propeller.rotation.z += 30 * this.time.deltaTime;


            //var globalEnemyPosition =  enemy.mesh.localToWorld(new THREE.Vector3());
            let diffPos = this.airplane.mesh.position.clone().sub( enemy.mesh.position.clone() );
            let d = diffPos.length();
            if ( d < this.state.game.enemyDistanceTolerance ) {
                this.particlesHolder.spawnParticles( enemy.mesh.position.clone(), 15, this.colors.red, 3 );

                this.enemiesPool.unshift( this.enemiesInUse.splice( i, 1 )[ 0 ] );
                this.mesh.remove( enemy.mesh );
                this.state.game.planeCollisionSpeedX = 100 * diffPos.x / d;
                this.state.game.planeCollisionSpeedY = 100 * diffPos.y / d;
                this.ambientLight.intensity = 2;

                this.removeEnergy();
                i--;
            } else if ( enemy.angle > Math.PI ) {
                this.enemiesPool.unshift( this.enemiesInUse.splice( i, 1 )[ 0 ] );
                this.mesh.remove( enemy.mesh );
                i--;
            }
        }
    }

    removeEnergy() {
        this.state.game.energy -= this.state.game.enemyValue;
        this.state.game.energy = Math.max( 0, this.state.game.energy );
    }

    resize() {

    }

    setDebug() {
        if ( !this.debug.active ) return

    }

    update( deltaTime ) {

    }

}
