export function mix( x, y, a ) {
    return x * ( 1 - a ) + y * a;
}

export function remap( x, oMin, oMax, nMin, nMax ) {
    return mix( nMin, nMax, ( x - oMin ) / ( oMax - oMin ) );
}

export function simplexNoise4d( x, y, z, w ) {
    return simplex.noise4d( x, y, z, w );
}

export function normalize( v, vmin, vmax, tmin, tmax ) {
    const nv = Math.max( Math.min( v, vmax ), vmin );
    const dv = vmax - vmin;
    const pc = ( nv - vmin ) / dv;
    const dt = tmax - tmin;
    return tmin + ( pc * dt );
}
