import * as THREE from 'three'
import Model from './Abstracts/Model.js'
import Experience from '../Experience.js'
import Debug from '../Utils/Debug.js'
import State from "../State.js";
import Materials from "../Materials/Materials.js";
import * as BufferGeometryUtils from "three/addons/utils/BufferGeometryUtils.js";

export default class Cloud extends Model {
    experience = Experience.getInstance()
    debug = Debug.getInstance()
    state = State.getInstance()
    materials = Materials.getInstance()
    scene = experience.scene
    time = experience.time
    camera = experience.camera.instance
    renderer = experience.renderer.instance
    resources = experience.resources
    container = new THREE.Group();
    colors = this.state.colors

    constructor() {
        super()

        this.setModel()
        this.setDebug()
    }

    setModel() {
        this.resources.items.chalkBoardModel.scene.rotation.x = Math.PI / 2;
        this.resources.items.chalkBoardModel.scene.rotation.x = Math.PI / 2;

        this.models = [
            this.resources.items.pencilModel.scene.clone(),
            this.resources.items.chalkBoardModel.scene.clone(),
            this.resources.items.eraserModel.scene.clone(),
            this.resources.items.rulerModel.scene.clone(),
        ];

        for ( let i = 0; i < this.models.length; i++ ) {

            // this.models[ i ].traverse( child => {
            //     if ( child.isMesh ) {
            //         child.material.side = THREE.BackSide
            //         child.position.z += Math.random() * 0.01;
            //     }
            // })

            this.models[ i ].scale.set( 70, 70, 70 );


            // Change center model to boundingBox
            const box = new THREE.Box3().setFromObject( this.models[ i ] );
            const centerBox = box.getCenter( new THREE.Vector3() );
            this.models[ i ].position.sub( centerBox );
        }

        this.mesh = new THREE.Group();
        this.mesh.name = "cloud";

        // var geom = new THREE.BoxGeometry( 20, 20, 20 );
        // var mat = new THREE.MeshPhongMaterial( {
        //     color: this.colors.white,
        //     transparent: true,
        //     opacity: .0,
        // } );

        var nBlocs = 9 + Math.floor(Math.random() * 3);
        var objects = [];

        for (var i = 0; i < nBlocs; i++) {
            let m = new THREE.Mesh();
            m.add(this.models[Math.floor(Math.random() * this.models.length)]);

            // Get random scale
            let s = 0.1 + Math.random() * 0.9;
            m.scale.set(s, s, s);
            m.rotation.z = Math.random() * Math.PI * 2;
            m.rotation.y = Math.random() * Math.PI * 2;

            let isValidPosition = false;
            let attempts = 0;
            const maxAttempts = 100; // максимальное количество попыток

            while (!isValidPosition && attempts < maxAttempts) {
                attempts++;
                // Generate random position
                let position = new THREE.Vector3(
                    i * 30,
                    Math.random() * 20,
                    100
                );

                // m.position.x = i * 15;
                // m.position.y = Math.random() * 10;
                // m.position.z = Math.random() * 10;

                m.position.copy(position);

                m.updateMatrixWorld(true);

                // create bounding sphere
                let sphere = new THREE.Sphere();
                let box = new THREE.Box3().setFromObject(m);
                box.getBoundingSphere(sphere);

                // check if sphere intersects with other spheres
                isValidPosition = true;
                for (let j = 0; j < objects.length; j++) {
                    let existingObject = objects[j];
                    let existingSphere = new THREE.Sphere();
                    let existingBox = new THREE.Box3().setFromObject(existingObject);
                    existingBox.getBoundingSphere(existingSphere);

                    if (sphere.intersectsSphere(existingSphere)) {
                        isValidPosition = false;
                        break;
                    }
                }

                if (isValidPosition) {
                    objects.push(m);
                    this.mesh.add(m);
                    m.castShadow = true;
                    m.receiveShadow = true;
                }
            }

            // not found suitable position
            // if (attempts >= maxAttempts) {
            //     console.warn('Не удалось найти подходящую позицию для объекта');
            // }
        }
    }

    rotate() {
        let l = this.mesh.children.length;
        for ( let i = 0; i < l; i++ ) {
            let m = this.mesh.children[ i ];
            m.rotation.z += Math.random() * .5 * ( i + 1 ) * this.time.deltaTime
            m.rotation.y += Math.random() * .2 * ( i + 1 ) * this.time.deltaTime
        }
    }

    resize() {

    }

    setDebug() {
        if ( !this.debug.active ) return

    }

    update( deltaTime ) {

    }

}
